import { useState } from 'react';
import { createContainer } from 'react-tracked';

const initialState = {
  count: 0,
  text: "Testing Text",
  textColor: 'red',
};
const useMyState = () => useState(initialState);
export const {
  Provider: SharedStateProvider,
  useTracked: useSharedState,
} = createContainer(useMyState);