import React, { Component } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons"

function focusSlide(targetID) {
  var comSlider = document.getElementById(targetID)
  comSlider.getElementsByClassName("slick-current")[0].focus();
  return (
    <>
    </>
  )
}

function SamplePrevArrow(props) {
    const targetID = props.targetID
    const { style, onClick } = props
    return (
      <div className="chevron-circle chevron-left" onClick={() => { onClick(); focusSlide(targetID);}}>
        <FontAwesomeIcon
          icon={faChevronLeft}
          style={{ zIndex: 1 }}
        />
      </div>
    )
  }

function SampleNextArrow(props) {
  const targetID = props.targetID
  const { style, onClick } = props
  return (
    <div className="chevron-circle chevron-right" onClick={() => { onClick(); focusSlide(targetID);}}>
      <FontAwesomeIcon
        icon={faChevronRight}
        style={{ zIndex: 1 }}
      />
    </div>
  )
}

export { SamplePrevArrow, SampleNextArrow }
